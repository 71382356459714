/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
    transition: all 0.5s;
    z-index: 997;
    padding: 15px 0;
    background: rgba(255, 255, 255, 1);
  }
  
  #header.header-scrolled, #header.header-inner-pages {
    /* background: rgba(40, 58, 90, 0.9); template color */
    /* background: rgba(48,51,107, 0.8); first color test */
    background: rgba(255, 255, 255, 1);
  }
  
  #header .logo {
    font-size: 1.2rem;
    margin: 0;
    padding: 0 0.7rem;
    line-height: 1;
    font-weight: 500;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin: auto;
  }
  
  #header .siteLogo{
    display: flex;
    flex-direction: row;
  }
  #header .siteLogo img{
    width: 80px !important;
  }
  #header .logo {
    /* color: #fff; template color */
    font-family: 'Roboto', sans-serif;
    color: rgba(48,51,107, 0.8);
    transition: all ease-in-out 0.3s;
  }
  #header .siteLogo:hover .logo{
    color: #47b2e4;
  }
  
  #header .logo img {
    max-height: 2rem;
  }
  #header .navWrapper{
    justify-content: space-between;
  }
  
  /*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
  /**
  * Desktop Navigation 
  */
  .navbar {
    padding: 0;
  }
  
  .navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }
  
  .navbar li {
    position: relative;
  }
  .header-scrolled .navbar .active {
    color: #47b2e4 !important;
  }
  .header-scrolled .navbar a{
    /* color: #fff; */
    color: rgba(48,51,107, 0.8);
  }
  .navbar a, .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 10px 20px;
    font-size: 15px;
    font-weight: 600;
    color: rgba(48,51,107, 0.8);
    white-space: nowrap;
    transition: 0.3s;
  }
  
  .navbar a i, .navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }
  .navbar a:hover, .navbar .active, .navbar .active:focus, .navbar li:hover > a {
    color: #47b2e4;
  }
  /**
  * Mobile Navigation 
  */
  .mobile-nav-toggle {
    color: rgba(48,51,107, 0.8);
    font-size: 28px;
    font-weight: 600;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
  }
  
  .mobile-nav-toggle.bi-x {
    color: #fff;
  }
  
  @media (max-width: 991px) {
    .mobile-nav-toggle {
      display: block;
    }
    .navbar ul {
      display: none;
    }
  }
  
  .navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(40, 58, 90, 0.9);
    transition: 0.3s;
    z-index: 999;
  }
  
  .navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
  }
  
  .navbar-mobile ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    border-radius: 10px;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
  }
  
  .navbar-mobile a {
    padding: 10px 20px;
    font-size: 15px;
    color: #37517e;
  }
  
  .navbar-mobile a:hover, .navbar-mobile .active, .navbar-mobile li:hover > a {
    color: #47b2e4;
  }
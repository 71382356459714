@import url(https://fonts.googleapis.com/css2?family=Calistoga&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Calistoga&family=Rubik:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
    transition: all 0.5s;
    z-index: 997;
    padding: 15px 0;
    background: rgba(255, 255, 255, 1);
  }
  
  #header.header-scrolled, #header.header-inner-pages {
    /* background: rgba(40, 58, 90, 0.9); template color */
    /* background: rgba(48,51,107, 0.8); first color test */
    background: rgba(255, 255, 255, 1);
  }
  
  #header .logo {
    font-size: 1.2rem;
    margin: 0;
    padding: 0 0.7rem;
    line-height: 1;
    font-weight: 500;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin: auto;
  }
  
  #header .siteLogo{
    display: flex;
    flex-direction: row;
  }
  #header .siteLogo img{
    width: 80px !important;
  }
  #header .logo {
    /* color: #fff; template color */
    font-family: 'Roboto', sans-serif;
    color: rgba(48,51,107, 0.8);
    transition: all ease-in-out 0.3s;
  }
  #header .siteLogo:hover .logo{
    color: #47b2e4;
  }
  
  #header .logo img {
    max-height: 2rem;
  }
  #header .navWrapper{
    justify-content: space-between;
  }
  
  /*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
  /**
  * Desktop Navigation 
  */
  .navbar {
    padding: 0;
  }
  
  .navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }
  
  .navbar li {
    position: relative;
  }
  .header-scrolled .navbar .active {
    color: #47b2e4 !important;
  }
  .header-scrolled .navbar a{
    /* color: #fff; */
    color: rgba(48,51,107, 0.8);
  }
  .navbar a, .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 10px 20px;
    font-size: 15px;
    font-weight: 600;
    color: rgba(48,51,107, 0.8);
    white-space: nowrap;
    transition: 0.3s;
  }
  
  .navbar a i, .navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }
  .navbar a:hover, .navbar .active, .navbar .active:focus, .navbar li:hover > a {
    color: #47b2e4;
  }
  /**
  * Mobile Navigation 
  */
  .mobile-nav-toggle {
    color: rgba(48,51,107, 0.8);
    font-size: 28px;
    font-weight: 600;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
  }
  
  .mobile-nav-toggle.bi-x {
    color: #fff;
  }
  
  @media (max-width: 991px) {
    .mobile-nav-toggle {
      display: block;
    }
    .navbar ul {
      display: none;
    }
  }
  
  .navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(40, 58, 90, 0.9);
    transition: 0.3s;
    z-index: 999;
  }
  
  .navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
  }
  
  .navbar-mobile ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    border-radius: 10px;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
  }
  
  .navbar-mobile a {
    padding: 10px 20px;
    font-size: 15px;
    color: #37517e;
  }
  
  .navbar-mobile a:hover, .navbar-mobile .active, .navbar-mobile li:hover > a {
    color: #47b2e4;
  }
/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
    width: 100%;
    height: auto;
    /* background: #37517e; template color */
    /* background: rgba(48,51,107, 0.9); */
    /* background: rgba(72, 127, 177, 1); */
    background: #5089C6;
  }
  
  #hero .container {
    padding-top: 72px;
  }
  
  #hero h1 {
    margin: 0 0 10px 0;
    font-size: 40px;
    font-weight: 700;
    line-height: 56px;
    color: #fff;
  }
  
  #hero h2 {
    color: rgba(255, 255, 255, 0.6);
    margin-bottom: 50px;
    font-size: 24px;
    font-weight: 600 !important;
  }
  
  #hero .btn-get-started {
    font-family: "Jost", sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 28px 11px 28px;
    border-radius: 50px;
    transition: 0.5s;
    margin: 10px 0 0 0;
    color: #fff;
    background: #47b2e4;
  }
  #hero .hero-img{
    display: flex;
  }
  #hero img{
    width: 80%;
    margin: auto;
  }
  #hero .btn-get-started:hover {
    background: #209dd8;
  }
  
  #hero .btn-watch-video {
    font-size: 16px;
    display: flex;
    align-items: center;
    transition: 0.5s;
    margin: 10px 0 0 25px;
    color: #fff;
    line-height: 1;
  }
  #hero .btn-watch-video i {
    line-height: 0;
    color: #fff;
    font-size: 32px;
    transition: 0.3s;
    margin-right: 8px;
  }
  
  #hero .btn-watch-video:hover i {
    color: #47b2e4;
  }
  
  #hero .animated {
    animation: up-down 2s ease-in-out infinite alternate-reverse both;
  }
  
  @media (max-width: 991px) {
    #hero {
      text-align: center;
    }
    #hero .animated {
      animation: none;
    }
    #hero .hero-img {
      text-align: center;
    }
    #hero .hero-img img {
      width: 80%;
    }
  }
  
  @media (max-width: 768px) {
    #hero h1 {
      font-size: 28px;
      line-height: 36px;
    }
    #hero h2 {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 30px;
    }
    #hero .hero-img img {
      width: 80%;
    }
  }
  
  @media (max-width: 575px) {
    #hero .hero-img img {
      width: 100%;
    }
    #hero .btn-get-started {
      font-size: 16px;
      padding: 10px 24px 11px 24px;
    }
  }
  
  @keyframes up-down {
    0% {
      transform: translateY(10px);
    }
    100% {
      transform: translateY(-10px);
    }
  }
  
/*--------------------------------------------------------------
# Cliens
--------------------------------------------------------------*/
.cliens {
  padding: 12px 0;
  text-align: center;
}

.cliens img {
  max-width: 50%;
  transition: all 0.4s ease-in-out;
  display: inline-block;
  padding: 15px 0;
  -webkit-filter: grayscale(100);
          filter: grayscale(100);
}
.section-bg {
  background-color: #f3f5fa;
}

.cliens img:hover {
  -webkit-filter: none;
          filter: none;
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .cliens img {
    max-width: 45%;
  }
}

/*--------------------------------------------------------------
# About Us
--------------------------------------------------------------*/
.about .content h3 {
    font-weight: 600;
    font-size: 26px;
  }
  
  .about .content ul {
    list-style: none;
    padding: 0;
  }
  
  .about .content ul li {
    padding-left: 28px;
    position: relative;
  }
  
  .about .content ul li + li {
    margin-top: 10px;
  }
  
  .about .content ul i {
    position: absolute;
    left: 0;
    top: 2px;
    font-size: 20px;
    color: #47b2e4;
    line-height: 1;
  }
  
  .about .content p:last-child {
    margin-bottom: 0;
  }
  
  .about .content .btn-learn-more {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 4px;
    transition: 0.3s;
    line-height: 1;
    color: #47b2e4;
    animation-delay: 0.8s;
    margin-top: 6px;
    border: 2px solid #47b2e4;
  }
  
  .about .content .btn-learn-more:hover {
    background: #47b2e4;
    color: #fff;
    text-decoration: none;
  }
  @media (max-width:468px) {
    .about p{
      width: 350px;
      padding: 0 1rem;
      margin: auto;
    }
  }
  @media (max-width:330px) {
    .about p{
      width: 100%;
    }
  }
/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
    box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
    padding: 40px 30px;
    transition: all ease-in-out 0.4s;
    background: #fff;
    border-radius: 1.4rem;
    min-height: 302px;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  
  .services .icon-box .icon {
    margin-bottom: 10px;
    display: flex;
  }
  .services .icon-box img{
    width: 6rem;
    margin: auto;
  }
  .services .icon-box h4 {
    font-weight: 500;
    margin-bottom: 15px;
    font-size: 24px;
    text-align: center;
  }
  
  .services .icon-box h4 {
    /* color: #37517e; */
    color: #5089C6;
    transition: ease-in-out 0.3s;
  }
  
  .services .icon-box p {
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
    text-align: center;
    padding: 0 2rem;
    color: black !important;
  }
  
  .services .icon-box:hover {
    transform: translateY(-10px);
  }
  
  .services .icon-box:hover h4 {
    color: #47b2e4;
  }
  @media (max-width:420px) {
    .services .section-title p{
      width: 330px;
      margin: auto;
    }
    .services .icon-box p{
      width: 230px;
      padding: 0;
      margin: auto;
    }
    .services .apps  h4{
      width: 236px;
      margin: auto;
      margin-bottom: 15px;
    }
  }
  @media (max-width:352px) {
    .services .section-title p{
      width: 100% ;
    }
  }
  @media (max-width:330px) {
    
    .services .icon-box p{
      width: 100%;
    }
    .services .apps  h4{
      width: 100%;
    }
  }
  
/*--------------------------------------------------------------
# Cta
--------------------------------------------------------------*/
.cta {
    /* background: linear-gradient(rgba(40, 58, 90, 0.9), rgba(40, 58, 90, 0.9)), url("../../img/cta-bg.jpg") fixed center center; */
    background: linear-gradient(rgba(72, 127, 177, 0.6), rgba(40, 58, 90, 0.6)), url(/static/media/cta-bg-1.c88b007f.jpg) fixed center center;
    background-size: cover;
    padding: 120px 0;
  }
  
  .cta h3 {
    color: #fff;
    font-size: 28px;
    font-weight: 700;
  }
  
  .cta p {
    color: #fff;
  }
  @media (min-width:768px) {
    .cta p{
      padding: 0 2rem 0 0rem;
      width: 93%;
    }
  }
  @media (max-width:427px) {
    .cta h3{
      padding: 0 2rem;
    }
  }
  
  .cta .cta-btn {
    /* font-family: "Jost", sans-serif; */
    font-family: 'Rubik',sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 40px;
    border-radius: 50px;
    transition: 0.5s;
    margin: 10px;
    border: 2px solid #fff;
    color: #fff;
  }
  
  .cta .cta-btn:hover {
    background: #47b2e4;
    border: 2px solid #47b2e4;
  }
  
  @media (max-width: 1024px) {
    .cta {
      background-attachment: scroll;
    }
  }
  
  @media (min-width: 769px) {
    .cta .cta-btn-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
  @media (max-width: 420px) {
    .cta p{
      width: 296px;
      margin: auto;
    }
  }
  @media (max-width:319px) {
    .cta p{
      width: 100%;
    }
  }
.portfolio .portfolio-content{
  margin: 0.6rem 0;
  background: #fff;
  border-radius: 15px;
  transition: all ease-in-out 0.4s;
  cursor: pointer;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
.portfolio .portfolio-content img{
  width: 80%;
  border-radius: 15px;
  margin: 1.2rem auto;
}
.portfolio .portfolio-content.reverse img{
  padding: 3.3rem;
}
@media (min-width:968px) {
  .portfolio-content.reverse .contentBlock{
    margin-left: 2.5rem !important;
  }
  .portfolio-content.yip p{
    padding-right: 2rem;
  }
}
@media (max-width:968px) {
  .portfolio-content.reverse .contentBlock{
    margin-top: -2.5rem !important;
  }
}
.portfolio .portfolio-content .img{
  padding: 0 2rem;
  display: flex;
}
.portfolio .portfolio-content .img , .portfolio .portfolio-content .portfolio-details{
  flex: 50% 1;
} 
.portfolio .portfolio-content .contentBlock{
  margin: auto;
  padding: 2rem;
}
.portfolio .portfolio-content.reverse{
  flex-direction: row-reverse;
}
.portfolio-content .social-icons{
  display: flex;
  flex-direction: row;
  margin-top: 1.8rem;
  padding: 1rem 0;
}
.portfolio .portfolio-content .social-icons i{
  color: #1b75bc;
  width: 2.3rem;
  height: 2.3rem;
  margin: 0.1rem 0.6rem;
  border-radius: 50%;
  background: #eff2f8;
  display: flex;
  transition: all ease-in-out 0.4s;
}
.portfolio-content .social-icons i::before{
  font-size: 1.23rem;
  margin: auto;
}
.portfolio .portfolio-content , .portfolio .portfolio-content{
  margin: 2rem 0;
}
.portfolio .portfolio-content:hover {
  transform: translateY(-10px);
}
.portfolio .portfolio-content .social-icons i:hover{
  color: #fff;
  background: #47b2e4;
  transform: translateY(-2px);
}
@media (max-width:968px) {
  .portfolio .portfolio-content img{
    width: 100%;
    padding: 0;
  }
  .portfolio .portfolio-content , .portfolio .portfolio-content.reverse{
    flex-direction: column;
  }
}
@media (max-width:411px) {
  .portfolio .section-title p{
    width: 280px;
    margin: auto;
  }
}
@media (max-width:319px) {
  .portfolio .section-title p{
    width: 100%;
  }
}
/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.team .member {
    position: relative;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    padding: 30px;
    border-radius: 5px;
    background: #fff;
    transition: 0.5s;
    height: 100%;
  }
  
  .team .member .pic {
    overflow: hidden;
    border-radius: 50%;
    width: 100%;
  }
  
  .team .member .pic img {
    transition: ease-in-out 0.3s;
  }
  
  .team .member:hover {
    transform: translateY(-10px);
  }
  
  .team .member .member-info {
    padding-left: 30px;
  }
  
  .team .member h4 {
    font-weight: 700;
    margin-bottom: 5px;
    font-size: 20px;
    color: #37517e;
  }
  
  .team .member span {
    display: block;
    font-size: 15px;
    padding-bottom: 10px;
    position: relative;
    font-weight: 500;
  }
  
  .team .member span::after {
    content: '';
    position: absolute;
    display: block;
    width: 50px;
    height: 1px;
    background: #cbd6e9;
    bottom: 0;
    left: 0;
  }
  
  .team .member p {
    margin: 10px 0 0 0;
    font-size: 14px;
  }
  
  .team .member .social {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  
  .team .member .social a {
    transition: ease-in-out 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    width: 32px;
    height: 32px;
    background: #eff2f8;
  }
  
  .team .member .social a i {
    color: #37517e;
    font-size: 16px;
    margin: 0 2px;
  }
  
  .team .member .social a:hover {
    background: #47b2e4;
  }
  
  .team .member .social a:hover i {
    color: #fff;
  }
  
  .team .member .social a + a {
    margin-left: 8px;
  }
  @media (min-width:992px) {
    .team .member{
      max-height: 290px;
    }
    .team .member{
      align-items: center;
    }
    .team .member-info{
      margin: auto;
    }
  }
  @media (max-width:992px) {
    .team .member{
      flex-direction: column;
    }
    .team .member .pic{
      display: flex;
      width: 60%;
      margin: auto;
      margin-bottom: 1rem;
    }
  }
  @media (max-width: 365px) {
    .team .section-title p{
      width: 296px;
      margin: auto;
    }
  }
  @media (max-width:319px) {
    .team .section-title p{
      width: 100%;
    }
  }
/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info {
    border-top: 3px solid #47b2e4;
    border-bottom: 3px solid #47b2e4;
    padding: 30px;
    background: #fff;
    width: 100%;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
  }
  
  .contact .info i {
    font-size: 20px;
    color: #47b2e4;
    float: left;
    width: 44px;
    height: 44px;
    background: #e7f5fb;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
  }
  
  .contact .info h4 {
    padding: 0 0 0 60px;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #37517e;
  }
  
  .contact .info p {
    padding: 0 0 10px 60px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #6182ba;
  }
  
  .contact .info .email p {
    padding-top: 5px;
  }
  
  .contact .info .social-links {
    padding-left: 60px;
  }
  
  .contact .info .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #333;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
    margin-right: 10px;
  }
  
  .contact .info .social-links a:hover {
    background: #47b2e4;
    color: #fff;
  }
  
  .contact .info .email:hover i, .contact .info .address:hover i, .contact .info .phone:hover i {
    background: #47b2e4;
    color: #fff;
  }
  
  .contact .php-email-form {
    width: 100%;
    border-top: 3px solid #47b2e4;
    border-bottom: 3px solid #47b2e4;
    padding: 30px;
    background: #fff;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
  }
  
  .contact .php-email-form .form-group {
    padding-bottom: 8px;
  }
  
  .contact .php-email-form .validate {
    display: none;
    color: red;
    margin: 0 0 15px 0;
    font-weight: 400;
    font-size: 13px;
  }
  
  .contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: left;
    padding: 15px;
    font-weight: 600;
  }
  
  .contact .php-email-form .error-message br + br {
    margin-top: 25px;
  }
  
  .contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
  }
  
  .contact .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
  }
  
  .contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    animation: animate-loading 1s linear infinite;
  }
  
  .contact .php-email-form .form-group {
    margin-bottom: 20px;
  }
  
  .contact .php-email-form label {
    padding-bottom: 8px;
  }
  
  .contact .php-email-form input, .contact .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
    border-radius: 4px;
  }
  
  .contact .php-email-form input:focus, .contact .php-email-form textarea:focus {
    border-color: #47b2e4;
  }
  
  .contact .php-email-form input {
    height: 44px;
  }
  
  .contact .php-email-form textarea {
    padding: 10px 12px;
  }
  
  .contact .php-email-form button[type="submit"] {
    background: #47b2e4;
    border: 0;
    padding: 12px 34px;
    color: #fff;
    transition: 0.4s;
    border-radius: 50px;
  }
  
  .contact .php-email-form button[type="submit"]:hover {
    background: #209dd8;
  }
  
  @keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
    font-size: 14px;
    /* background: #37517e; */
    /* background: rgba(72, 127, 177, 1); */
    background: #5089C6;
  }
  
  #footer .footer-newsletter {
    padding: 50px 0;
    background: #f3f5fa;
    text-align: center;
    font-size: 15px;
    color: #444444;
  }
  
  #footer .footer-newsletter h4 {
    font-size: 24px;
    margin: 0 0 20px 0;
    padding: 0;
    line-height: 1;
    font-weight: 600;
    color: #37517e;
  }
  
  #footer .footer-newsletter form {
    margin-top: 30px;
    background: #fff;
    padding: 6px 10px;
    position: relative;
    border-radius: 50px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.06);
    text-align: left;
  }
  
  #footer .footer-newsletter form input[type="email"] {
    border: 0;
    padding: 4px 8px;
    width: calc(100% - 100px);
  }
  
  #footer .footer-newsletter form input[type="submit"] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 20px;
    background: #47b2e4;
    color: #fff;
    transition: 0.3s;
    border-radius: 50px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  }
  
  #footer .footer-newsletter form input[type="submit"]:hover {
    background: #209dd8;
  }
  
  #footer .footer-top {
    padding: 60px 0 30px 0;
    background: #fff;
  }
  
  #footer .footer-top .footer-contact {
    margin-bottom: 30px;
  }
  
  #footer .footer-top .footer-contact h3 {
    font-size: 28px;
    margin: 0 0 10px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    text-transform: uppercase;
    font-weight: 600;
    color: #37517e;
  }
  
  #footer .footer-top .footer-contact p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: "Jost", sans-serif;
    color: #5e5e5e;
  }
  
  #footer .footer-top h4 {
    font-size: 16px;
    font-weight: bold;
    color: #37517e;
    position: relative;
    padding-bottom: 12px;
  }
  
  #footer .footer-top .footer-links {
    margin-bottom: 30px;
  }
  
  #footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  #footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: #47b2e4;
    font-size: 18px;
    line-height: 1;
  }
  
  #footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
  }
  
  #footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
  }
  
  #footer .footer-top .footer-links ul a {
    color: #777777;
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
  }
  
  #footer .footer-top .footer-links ul a:hover {
    text-decoration: none;
    color: #47b2e4;
  }
  
  #footer .footer-top .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #47b2e4;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
  }
  
  #footer .footer-top .social-links a:hover {
    background: #209dd8;
    color: #fff;
    text-decoration: none;
  }
  
  #footer .footer-bottom {
    padding-top: 30px;
    padding-bottom: 30px;
    color: #fff;
  }
  
  #footer .copyright {
    float: left;
  }
  
  #footer .credits {
    float: right;
    font-size: 13px;
  }
  
  #footer .credits a {
    transition: 0.3s;
  }
  
  @media (max-width: 768px) {
    #footer .footer-bottom {
      padding-top: 20px;
      padding-bottom: 20px;
    }
    #footer .copyright, #footer .credits {
      text-align: center;
      float: none;
    }
    #footer .credits {
      padding-top: 4px;
    }
  }
  
/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 996;
    background: #47b2e4;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    transition: all 0.4s;
  }
  
  .back-to-top i {
    font-size: 24px;
    color: #fff;
    line-height: 0;
  }
  
  .back-to-top:hover {
    background: #6bc1e9;
    color: #fff;
  }
  
  .back-to-top.active {
    visibility: visible;
    opacity: 1;
  }
  
/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9998;
    overflow: hidden;
    background: #5089C6;
  }
  
  #preloader:before {
    content: "";
    position: fixed;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    border: 6px solid #5089C6;
    border-top-color: #fff;
    border-bottom-color: #fff;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: animate-preloader 1s linear infinite;
  }
  .preloader .text{
    position: fixed;
    width: 100%;
    height: 32px;
    max-height: 80px;
    z-index: 9999;
    top: calc(50% + 60px);
  }
  .preloader .text h3{
    color: #fff;
    margin: auto;
    padding: 1rem;
    text-align: center;
    /* z-index: 9999; */
  }
  
  @keyframes animate-preloader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
/* Loading the Font */
/* Caslitoga */
/* Rubik */
/* Roboto */
html{
  scroll-behavior: smooth;
}
body {
    font-family: 'Rubik', sans-serif;
    color: #444444;
  }
  
  a {
    color: #47b2e4;
    text-decoration: none;
  }
  
  a:hover {
    color: #73c5eb;
    text-decoration: none;
  }
  
  h1, h2, h3, h4, h5, h6 {
    /* font-family: "Jost", sans-serif; */
    /* font-family: 'Calistoga', cursive; */
    font-family: 'Rubik',sans-serif;
    font-weight: 800 !important;
  }
  /*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 130px 0;
  /* padding-top: 130px; */
  overflow: hidden;
}

.section-bg {
  background-color: #f3f5fa;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 32px;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  /* color: #37517e; */
  color:  rgba(72, 127, 177, 1);
}

.section-title h2::before {
  content: '';
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.section-title h2::after {
  content: '';
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #47b2e4;
  bottom: 0;
  left: calc(50% - 20px);
}

.section-title p {
  margin-bottom: 0;
}
/* Animations*/
  
  @keyframes up-down {
    0% {
      transform: translateY(20px);
    }
    100% {
      transform: translateY(-20px);
    }
  }

.comming-soon{
    margin-top: 110px;
}
.comming-soon h1{
    margin: 2rem 0 0 0 ;
    text-align: center;
    color:  rgba(48,51,107, 0.8);
    font-size: 48px;
}
.imgContainer{
    display: flex;
}
.commingSoonImg{
    width: 25rem;
    margin: auto;
    animation:  up-down 2s ease-in-out infinite alternate-reverse both;
}


.loader { 
    width:200px; 
    margin:0 auto;
    border-radius:10px;
    border:4px solid transparent;
    position:relative;
    padding:5px;
  }
  .loader:before {
    content:'';
    border:1px solid #5089C6; 
    border-radius:10px;
    position:absolute;
    top:-4px; 
    right:-4px; 
    bottom:-4px; 
    left:-4px;
  }
  .loader .loaderBar { 
    position:absolute;
    border-radius:10px;
    padding: 5px;
    top:0;
    right:100%;
    bottom:0;
    left:0;
    background:#5089C6; 
    width:0;
    animation:borealisBar 2s linear infinite;
  }
  
  @keyframes borealisBar {
    0% {
      left:0%;
      right:100%;
      width:0%;
    }
    10% {
      left:0%;
      right:75%;
      width:25%;
    }
    90% {
      right:0%;
      left:75%;
      width:25%;
    }
    100% {
      left:100%;
      right:0%;
      width:0%;
    }
  }
/* Animations*/

@keyframes up-down {
  0% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(-20px);
  }
}

.comming-soon{
  margin-top: 110px;
}
.comming-soon h1{
  margin: 2rem 0 0 0 ;
  text-align: center;
  color:  rgba(48,51,107, 0.8);
  font-size: 48px;
}
.imgContainer{
  display: flex;
}
.commingSoonImg{
  width: 25rem;
  margin: auto;
  animation:  up-down 2s ease-in-out infinite alternate-reverse both;
}


.loader { 
  width:200px; 
  margin:0 auto;
  border-radius:10px;
  border:4px solid transparent;
  position:relative;
  padding:5px;
}
.loader:before {
  content:'';
  border:1px solid #5089C6; 
  border-radius:10px;
  position:absolute;
  top:-4px; 
  right:-4px; 
  bottom:-4px; 
  left:-4px;
}
.loader .loaderBar { 
  position:absolute;
  border-radius:10px;
  padding: 5px;
  top:0;
  right:100%;
  bottom:0;
  left:0;
  background:#5089C6; 
  width:0;
  animation:borealisBar 2s linear infinite;
}

.course .course-content{
  margin: 0.6rem 0;
  background: #fff;
  border-radius: 15px;
  transition: all ease-in-out 0.4s;
  cursor: pointer;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
.course .course-content img{
  width: 80%;
  border-radius: 15px;
  margin: 1.2rem auto;
}
.course .course-content.reverse img{
  padding: 3.3rem;
}
@media (min-width:968px) {
  .course-content.reverse .contentBlock{
    margin-left: 2.5rem !important;
  }
  .course-content.yip p{
    padding-right: 2rem;
  }
}
@media (max-width:968px) {
  .course-content.reverse .contentBlock{
    margin-top: -2.5rem !important;
  }
}

.course .course-content .img{
  padding: 0 2rem;
  display: flex;
}
.course .course-content .img , .course .course-content .course-details{
  flex: 50% 1;
} 
.course .course-content .contentBlock{
  margin: auto;
  padding: 2rem;
}
.course .course-content.reverse{
  flex-direction: row-reverse;
}
.course-content .social-icons{
  display: flex;
  flex-direction: row;
  margin-top: 1.8rem;
  padding: 1rem 0;
}
.course .course-content .social-icons i{
  color: #1b75bc;
  width: 2.3rem;
  height: 2.3rem;
  margin: 0.1rem 0.6rem;
  border-radius: 50%;
  background: #eff2f8;
  display: flex;
  transition: all ease-in-out 0.4s;
}
.course-content .social-icons i::before{
  font-size: 1.23rem;
  margin: auto;
}
.course .course-content , .course .course-content{
  margin: 2rem 0;
}
.course .course-content:hover {
  transform: translateY(-10px);
}
.course .course-content .social-icons i:hover{
  color: #fff;
  background: #47b2e4;
  transform: translateY(-2px);
}
@media (max-width:968px) {
  .course .course-content img{
    width: 100%;
    padding: 0;
  }
  .course .course-content , .course .course-content.reverse{
    flex-direction: column;
  }
}
@media (max-width:411px) {
  .course .section-title p{
    width: 280px;
    margin: auto;
  }
}
@media (max-width:319px) {
  .course .section-title p{
    width: 100%;
  }
}

@keyframes borealisBar {
  0% {
    left:0%;
    right:100%;
    width:0%;
  }
  10% {
    left:0%;
    right:75%;
    width:25%;
  }
  90% {
    right:0%;
    left:75%;
    width:25%;
  }
  100% {
    left:100%;
    right:0%;
    width:0%;
  }
}

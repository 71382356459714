/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
/* Loading the Font */
/* Caslitoga */
@import url('https://fonts.googleapis.com/css2?family=Calistoga&display=swap');
/* Rubik */
@import url('https://fonts.googleapis.com/css2?family=Calistoga&family=Rubik:wght@300&display=swap');
/* Roboto */
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
html{
  scroll-behavior: smooth;
}
body {
    font-family: 'Rubik', sans-serif;
    color: #444444;
  }
  
  a {
    color: #47b2e4;
    text-decoration: none;
  }
  
  a:hover {
    color: #73c5eb;
    text-decoration: none;
  }
  
  h1, h2, h3, h4, h5, h6 {
    /* font-family: "Jost", sans-serif; */
    /* font-family: 'Calistoga', cursive; */
    font-family: 'Rubik',sans-serif;
    font-weight: 800 !important;
  }
  /*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 130px 0;
  /* padding-top: 130px; */
  overflow: hidden;
}

.section-bg {
  background-color: #f3f5fa;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 32px;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  /* color: #37517e; */
  color:  rgba(72, 127, 177, 1);
}

.section-title h2::before {
  content: '';
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.section-title h2::after {
  content: '';
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #47b2e4;
  bottom: 0;
  left: calc(50% - 20px);
}

.section-title p {
  margin-bottom: 0;
}
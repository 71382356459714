.portfolio .portfolio-content{
  margin: 0.6rem 0;
  background: #fff;
  border-radius: 15px;
  transition: all ease-in-out 0.4s;
  cursor: pointer;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
.portfolio .portfolio-content img{
  width: 80%;
  border-radius: 15px;
  margin: 1.2rem auto;
}
.portfolio .portfolio-content.reverse img{
  padding: 3.3rem;
}
@media (min-width:968px) {
  .portfolio-content.reverse .contentBlock{
    margin-left: 2.5rem !important;
  }
  .portfolio-content.yip p{
    padding-right: 2rem;
  }
}
@media (max-width:968px) {
  .portfolio-content.reverse .contentBlock{
    margin-top: -2.5rem !important;
  }
}
.portfolio .portfolio-content .img{
  padding: 0 2rem;
  display: flex;
}
.portfolio .portfolio-content .img , .portfolio .portfolio-content .portfolio-details{
  flex: 50%;
} 
.portfolio .portfolio-content .contentBlock{
  margin: auto;
  padding: 2rem;
}
.portfolio .portfolio-content.reverse{
  flex-direction: row-reverse;
}
.portfolio-content .social-icons{
  display: flex;
  flex-direction: row;
  margin-top: 1.8rem;
  padding: 1rem 0;
}
.portfolio .portfolio-content .social-icons i{
  color: #1b75bc;
  width: 2.3rem;
  height: 2.3rem;
  margin: 0.1rem 0.6rem;
  border-radius: 50%;
  background: #eff2f8;
  display: flex;
  transition: all ease-in-out 0.4s;
}
.portfolio-content .social-icons i::before{
  font-size: 1.23rem;
  margin: auto;
}
.portfolio .portfolio-content , .portfolio .portfolio-content{
  margin: 2rem 0;
}
.portfolio .portfolio-content:hover {
  transform: translateY(-10px);
}
.portfolio .portfolio-content .social-icons i:hover{
  color: #fff;
  background: #47b2e4;
  transform: translateY(-2px);
}
@media (max-width:968px) {
  .portfolio .portfolio-content img{
    width: 100%;
    padding: 0;
  }
  .portfolio .portfolio-content , .portfolio .portfolio-content.reverse{
    flex-direction: column;
  }
}
@media (max-width:411px) {
  .portfolio .section-title p{
    width: 280px;
    margin: auto;
  }
}
@media (max-width:319px) {
  .portfolio .section-title p{
    width: 100%;
  }
}
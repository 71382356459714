/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
    box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
    padding: 40px 30px;
    transition: all ease-in-out 0.4s;
    background: #fff;
    border-radius: 1.4rem;
    min-height: 302px;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  
  .services .icon-box .icon {
    margin-bottom: 10px;
    display: flex;
  }
  .services .icon-box img{
    width: 6rem;
    margin: auto;
  }
  .services .icon-box h4 {
    font-weight: 500;
    margin-bottom: 15px;
    font-size: 24px;
    text-align: center;
  }
  
  .services .icon-box h4 {
    /* color: #37517e; */
    color: #5089C6;
    transition: ease-in-out 0.3s;
  }
  
  .services .icon-box p {
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
    text-align: center;
    padding: 0 2rem;
    color: black !important;
  }
  
  .services .icon-box:hover {
    transform: translateY(-10px);
  }
  
  .services .icon-box:hover h4 {
    color: #47b2e4;
  }
  @media (max-width:420px) {
    .services .section-title p{
      width: 330px;
      margin: auto;
    }
    .services .icon-box p{
      width: 230px;
      padding: 0;
      margin: auto;
    }
    .services .apps  h4{
      width: 236px;
      margin: auto;
      margin-bottom: 15px;
    }
  }
  @media (max-width:352px) {
    .services .section-title p{
      width: 100% ;
    }
  }
  @media (max-width:330px) {
    
    .services .icon-box p{
      width: 100%;
    }
    .services .apps  h4{
      width: 100%;
    }
  }
  
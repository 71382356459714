/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 996;
    background: #47b2e4;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    transition: all 0.4s;
  }
  
  .back-to-top i {
    font-size: 24px;
    color: #fff;
    line-height: 0;
  }
  
  .back-to-top:hover {
    background: #6bc1e9;
    color: #fff;
  }
  
  .back-to-top.active {
    visibility: visible;
    opacity: 1;
  }
  
/*--------------------------------------------------------------
# Cliens
--------------------------------------------------------------*/
.cliens {
  padding: 12px 0;
  text-align: center;
}

.cliens img {
  max-width: 50%;
  transition: all 0.4s ease-in-out;
  display: inline-block;
  padding: 15px 0;
  filter: grayscale(100);
}
.section-bg {
  background-color: #f3f5fa;
}

.cliens img:hover {
  filter: none;
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .cliens img {
    max-width: 45%;
  }
}

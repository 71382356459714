/* Animations*/
@-webkit-keyframes up-down {
  0% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(-20px);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(-20px);
  }
}

.comming-soon{
  margin-top: 110px;
}
.comming-soon h1{
  margin: 2rem 0 0 0 ;
  text-align: center;
  color:  rgba(48,51,107, 0.8);
  font-size: 48px;
}
.imgContainer{
  display: flex;
}
.commingSoonImg{
  width: 25rem;
  margin: auto;
  animation:  up-down 2s ease-in-out infinite alternate-reverse both;
}


.loader { 
  width:200px; 
  margin:0 auto;
  border-radius:10px;
  border:4px solid transparent;
  position:relative;
  padding:5px;
}
.loader:before {
  content:'';
  border:1px solid #5089C6; 
  border-radius:10px;
  position:absolute;
  top:-4px; 
  right:-4px; 
  bottom:-4px; 
  left:-4px;
}
.loader .loaderBar { 
  position:absolute;
  border-radius:10px;
  padding: 5px;
  top:0;
  right:100%;
  bottom:0;
  left:0;
  background:#5089C6; 
  width:0;
  animation:borealisBar 2s linear infinite;
}

.course .course-content{
  margin: 0.6rem 0;
  background: #fff;
  border-radius: 15px;
  transition: all ease-in-out 0.4s;
  cursor: pointer;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
.course .course-content img{
  width: 80%;
  border-radius: 15px;
  margin: 1.2rem auto;
}
.course .course-content.reverse img{
  padding: 3.3rem;
}
@media (min-width:968px) {
  .course-content.reverse .contentBlock{
    margin-left: 2.5rem !important;
  }
  .course-content.yip p{
    padding-right: 2rem;
  }
}
@media (max-width:968px) {
  .course-content.reverse .contentBlock{
    margin-top: -2.5rem !important;
  }
}

.course .course-content .img{
  padding: 0 2rem;
  display: flex;
}
.course .course-content .img , .course .course-content .course-details{
  flex: 50%;
} 
.course .course-content .contentBlock{
  margin: auto;
  padding: 2rem;
}
.course .course-content.reverse{
  flex-direction: row-reverse;
}
.course-content .social-icons{
  display: flex;
  flex-direction: row;
  margin-top: 1.8rem;
  padding: 1rem 0;
}
.course .course-content .social-icons i{
  color: #1b75bc;
  width: 2.3rem;
  height: 2.3rem;
  margin: 0.1rem 0.6rem;
  border-radius: 50%;
  background: #eff2f8;
  display: flex;
  transition: all ease-in-out 0.4s;
}
.course-content .social-icons i::before{
  font-size: 1.23rem;
  margin: auto;
}
.course .course-content , .course .course-content{
  margin: 2rem 0;
}
.course .course-content:hover {
  transform: translateY(-10px);
}
.course .course-content .social-icons i:hover{
  color: #fff;
  background: #47b2e4;
  transform: translateY(-2px);
}
@media (max-width:968px) {
  .course .course-content img{
    width: 100%;
    padding: 0;
  }
  .course .course-content , .course .course-content.reverse{
    flex-direction: column;
  }
}
@media (max-width:411px) {
  .course .section-title p{
    width: 280px;
    margin: auto;
  }
}
@media (max-width:319px) {
  .course .section-title p{
    width: 100%;
  }
}

@keyframes borealisBar {
  0% {
    left:0%;
    right:100%;
    width:0%;
  }
  10% {
    left:0%;
    right:75%;
    width:25%;
  }
  90% {
    right:0%;
    left:75%;
    width:25%;
  }
  100% {
    left:100%;
    right:0%;
    width:0%;
  }
}